import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

export default function TermsOfUse() {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
          paddingLeft: "25%",
          paddingRight: "25%",
          marginBottom: "10%",
        }}
      >
        <Typography style={{ fontSize: "50px" }}>Terms of Use</Typography>

        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Last Updated: 1 June 2024
        </Typography>
        <Typography variant={"h1"} sx={{ paddingTop: "5%" }}>
          Introduction
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          Welcome to InspectForm, a web platform operated by Aussielead Pty Ltd
          ("we", "us", or "our"). InspectForm allows users to customize
          inspection forms and utilize QR code scanning to submit forms. By
          accessing or using InspectForm, you, whether personally or on behalf
          of an entity ("you") agree to comply with and be bound by these Terms
          of Use ("Terms"). IF YOU DO NOT AGREE WITH THESE TERMS, PLEASE DO NOT
          USE OUR PLATFORM.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          1. Acceptance of Terms
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          By using InspectForm, you agree to be bound by these Terms, our
          Privacy Policy, and any additional terms applicable to certain
          programs in which you may elect to participate. IF YOU DO NOT AGREE TO
          ALL OF THESE TERMS, DO NOT USE INSPECTFORM.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          2. Changes to Terms
        </Typography>
        <Typography sx={{ paddingTop: "2%", fontSize: "15px" }}>
          We reserve the right to change these Terms at any time. We will notify
          you of any changes by posting the new Terms on inspectform.com. You
          are advised to review these Terms periodically for any changes. Your
          continued use of InspectForm following the posting of changes
          constitutes your acceptance of those changes.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          3. Use of the Platform
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.1 Eligibility
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          You must be at least 18 years old to use InspectForm. By using
          InspectForm, you represent and warrant that you are at least 18 years
          old and have the legal capacity to enter into these Terms.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.2 Account Registration
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          To access InspectForm, you are required to register for an account
          using your email address. During registration, InspectForm will send a
          verification code to your nominated email address. You agree to
          provide accurate, current, and complete information during the
          registration process and to update such information to keep it
          accurate, current, and complete. You are responsible for safeguarding
          your password and any other credentials used to access your account.
          You agree not to disclose your password to any third party and to
          notify us immediately of any unauthorized use of your account.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          {" "}
          3.3 User Conduct
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          You agree not to use InspectForm for any unlawful or prohibited
          activity. You shall not:
        </Typography>

        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          1. Use InspectForm in any manner that could disable, overburden,
          damage, or impair the platform or interfere with any other party's use
          of the platform.
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          2. Obtain or attempt to obtain any materials or information through
          any means not intentionally made available or provided through the
          platform.
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          3. Use InspectForm to upload, transmit, distribute, or otherwise make
          available any content that is unlawful, defamatory, obscene, or
          otherwise objectionable.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.4 Free trials and pilots.
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          InspectForm may offer you a free trial or pilot to allow you to try
          our Service. InspectForm reserves the right to set eligibility
          requirements and the duration for free trials and pilots.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.5 Account cancellation.
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          You can stop using the InspectForm and close your account by emailing
          us.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.6 Changes to Pricing.
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          InspectForm reserves the right to change its prices at any time. If
          you are on a subscription plan, changes to pricing will not apply
          until your next renewal or thirty (30) days after notice, whichever is
          later.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          4. Platform
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          4.1 Form Owners
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          Form owners can create, customize, and manage inspection forms on
          InspectForm. As a form owner, you are responsible for ensuring that
          the forms you create and distribute comply with all applicable laws
          and regulations.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          4.2 Form Recipients
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          Form owners can create recipients and verify recipients through email
          and/or mobile within InspectForm. You agree to provide accurate,
          current, and complete information when creating and verifying
          recipients. By using InspectForm to verify recipients, you represent
          and warrant that you have obtained the necessary consents and
          permissions required by applicable laws to collect and use the
          recipients' contact information.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          4.3 QR Code Functionality
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          InspectForm includes functionality for creating and scanning QR codes
          to submit forms. You are responsible for ensuring that QR codes
          generated and scanned comply with all applicable laws and regulations.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          4.4 Data Submission
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          By submitting data through InspectForm, you grant Aussielead Pty Ltd a
          worldwide, non-exclusive, royalty-free, fully paid-up, transferable,
          and sublicensable license to use, reproduce, modify, create derivative
          works from, distribute, and display such data in connection with
          operating and providing the platform.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          5. Intellectual Property
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          All content, features, and functionality on InspectForm, including but
          not limited to text, graphics, logos, icons, images, audio clips,
          digital downloads, data compilations, and software, are the exclusive
          property of Aussielead Pty Ltd or its licensors and are protected by
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          6. Privacy
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          {" "}
          Your use of InspectForm is also governed by our Privacy Policy, which
          describes how we collect, use, transfer, discloses and stores your
          personal data. By signing up an InspectForm account, you confirm that
          you have read, understood and agree to described in the Privacy
          Policy.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          7. Disclaimer
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          InspectForm is provided on an "as is" and "as available" basis.
          Aussielead Pty Ltd makes no representations or warranties of any kind,
          express or implied, as to the operation of the platform, or the
          information, content, or materials included on the platform. To the
          fullest extent permissible by applicable law, Aussielead Pty Ltd
          disclaims all warranties, express or implied, including, but not
          limited to, implied warranties of merchantability and fitness for a
          particular purpose. Without limiting the foregoing, Aussielead Pty Ltd
          does not warrant that the platform, its content, or the materials will
          be accurate, reliable, error-free, or uninterrupted, or that defects
          will be corrected.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          8. Limitation of Liability
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          To the fullest extent permitted by law, Aussielead Pty Ltd shall not
          be liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from: Your use or inability to use
          InspectForm. Any unauthorized access to or use of our servers and/or
          any personal information stored therein. Any interruption or cessation
          of transmission to or from our platform. Any bugs, viruses, trojan
          horses, or the like that may be transmitted to or through our platform
          by any third party. Any errors or omissions in any content or for any
          loss or damage incurred as a result of your use of any content posted,
          emailed, transmitted, or otherwise made available through InspectForm.
        </Typography>
        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          9. Governing Law
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          These Terms shall be governed and construed in accordance with the
          laws of the jurisdiction in which Aussielead Pty Ltd is headquartered,
          without regard to its conflict of law provisions.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          10. Termination
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We reserve the right to terminate or suspend your account and access
          to InspectForm at our sole discretion, without prior notice or
          liability, for any reason whatsoever, including without limitation if
          you breach the Terms. Upon termination, your right to use InspectForm
          will immediately cease.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          11. Contact Us
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          If you have any questions about these Terms, please contact us at:
          info@inspectform.com
        </Typography>
      </Grid>
    </div>
  );
}
