import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

export default function PrivacyPolicy() {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
          paddingLeft: "25%",
          paddingRight: "25%",
          marginBottom: "10%",
        }}
      >
        <Typography style={{ fontSize: "50px" }}>Privacy Policy</Typography>

        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Last Updated: 1 June 2024
        </Typography>
        <Typography variant={"h1"} sx={{ paddingTop: "5%" }}>
          Introduction
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          Welcome to InspectForm platform (the "Service"),, a web platform
          operated by Aussielead Pty Ltd ("we", "us", or "our"). This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you use our platform. By using InspectForm, you
          consent to the data practices described in this policy. If you do not
          want your information processed in accordance with this Privacy Policy
          in general or any part of it, YOU SHOULD NOT USE OUR SERVICE. This
          policy applies to all users of the Service.
        </Typography>
        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          1. Information We Collect
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          1.1 Personal Information
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          When you register for an account, we collect personal information such
          as your first name, last name, birthdate, email address, phone number,
          and any other information you provide during the registration process.
          When you create your projects, forms and recipients, you can input
          address info and recipients' contacts including name, email or mobile
          number. We use this information to operate, maintain, improve and
          provide the features and functionality of the Service to you, to
          correspond with you, and to address any issues you raise about the
          Service.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          1.2 Usage Data
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We may collect information about your usage of the platform, including
          your IP address, browser type, operating system, access times, and the
          pages you have viewed directly before and after accessing InspectForm.
          We will directly collect or generate certain information about your
          use of the Service (such as user activity data, analytics event data,
          and clickstream data) for data analytics and machine learning, and to
          help us measure traffic and usage trends for the Service. We may also
          use third party analytics tools that automatically collect information
          sent by your browser or mobile device, including the pages you visit
          and other information , that assists us in improving the Service.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          1.3 Cookies and Tracking Technologies
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We use cookies and similar tracking technologies to track the activity
          on our platform and hold certain information. Cookies are files with
          small amounts of data which may include an anonymous unique
          identifier. You can instruct your browser to refuse all cookies or to
          indicate when a cookie is being sent.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          2. How We Use Your Information
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          2.1 To Provide and Maintain Our Service
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We use your personal information to create and manage your account,
          provide customer support, and to send you technical notices, updates,
          security alerts, and administrative messages.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          2.2 To Improve Our Service (including analytics and machine learning)
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We use the information to understand and analyze how you use our
          platform and services, develop new products, services, features, and
          functionality.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          2.3 To Communicate with You
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We may use your personal information to contact you with newsletters,
          marketing or promotional materials, and other information that may be
          of interest to you. You can opt-out of receiving any, or all, of these
          communications from us by following the unsubscribe link or
          instructions provided in any email we send.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          2.4 To Process Transactions
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We use your information to process your payments, subscriptions, and
          to manage your orders.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          3. Sharing Your Information
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.1 Service Providers
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We may employ third-party companies and individuals to facilitate our
          platform ("Service Providers"), to provide the platform on our behalf,
          to perform platform-related services, or to assist us in analyzing how
          our platform is used.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.2 Compliance with Laws
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We will disclose your personal information where required to do so by
          law or subpoena or if we believe that such action is necessary to
          comply with the law and the reasonable requests of law enforcement.
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          3.3 Business Transfers
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          If Aussielead Pty Ltd is involved in a merger, acquisition, or asset
          sale, your personal information may be transferred. We will provide
          notice before your personal information is transferred and becomes
          subject to a different Privacy Policy.
        </Typography>
        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          4. Data Security
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          5. Your Data Protection Rights
        </Typography>
        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          5.1 Access, Correction, and Deletion
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          You have the right to access, correct, or delete your personal
          information stored by us. You can update your account information by
          logging into your account or by contacting us directly. Following
          termination or deactivation of your user account, we will retain your
          profile information and User Content for a commercially reasonable
          time, and for as long as we have a valid purpose to do so. In
          particular, we will retain your information for the purpose of
          complying with its legal and audit obligations, and for backup and
          archival purposes.
        </Typography>

        <Typography sx={{ paddingTop: "3%", color: "black" }} variant={"h4"}>
          5.2 Opt-Out of Marketing Communications
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          You can opt-out of receiving marketing emails and SMS messages from us
          by following the unsubscribe instructions provided in those messages.
          You may also update your preferences in your account settings.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          6. Children's Privacy
        </Typography>

        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          InspectForm is not intended for use by children under the age of 18.
          We do not knowingly collect personally identifiable information from
          children under 18. If you become aware that a child has provided us
          with personal information, please contact us. If we become aware that
          we have collected personal information from children without
          verification of parental consent, we take steps to remove that
          information from our servers.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          We may update our Privacy Policy from time to time to reflect our
          current practice and ensure compliance with applicable laws. When we
          post changes to this policy, we will revise the “Last Updated” date at
          the top of this policy on this page. You are advised to review this
          Privacy Policy periodically for any changes. Changes to this Privacy
          Policy are effective when they are posted on this page.
        </Typography>

        <Typography sx={{ paddingTop: "5%" }} variant={"h3"}>
          8. Contact Us
        </Typography>
        <Typography sx={{ paddingTop: "1%", fontSize: "15px" }}>
          If you have any questions about this Privacy Policy, please contact us
          at: info@inspectform.com
        </Typography>
      </Grid>
    </div>
  );
}
