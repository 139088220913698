import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

export default function ThankYou() {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
        }}
      >
        <Grid
          style={{
            paddingTop: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "20%",
            }}
          >
            Your message has been successfully received, one of our experienced
            consultants will be in touch with you within 24 hours to discuss
            your project in more detail and answer any questions you may have.If
            you prefer, give us a ring on
            <a
              href="tel:+61422658838"
              style={{
                color: "#000000",
              }}
            >
              +61 422 658 838
            </a>{" "}
            or send us{" "}
            <a
              href="mailto:info@inspectform.com"
              style={{
                color: "#B32D2A",
                textDecoration: "none",
              }}
            >
              info@inspectform.com
            </a>{" "}
            an email.
          </Typography>
        </Grid>

        <Typography display="block" variant={"h2"}></Typography>
      </Grid>
    </div>
  );
}
