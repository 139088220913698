import { Divider, Grid } from "@mui/material";
import "../App.css";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import AppsIcon from "@mui/icons-material/Apps";
import Box from "@mui/material/Box";
import palette from "../theme/palette";
import Contact from "./Contact";
import { TypeAnimation } from "react-type-animation";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ScheduleIcon from "@mui/icons-material/Schedule";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SmartToyIcon from "@mui/icons-material/SmartToy";

const statusIcon = (status: String, color: string) => {
  const upperStatus = status.toUpperCase();
  const fontSize = 100;
  switch (upperStatus) {
    case "FORM":
      return (
        <AssignmentIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "REMINDER":
      return (
        <ScheduleIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "NOTIFICATION":
      return (
        <NotificationsActiveIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "QR":
      return (
        <QrCodeIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "APPLICATION":
      return (
        <AppsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "AI":
      return (
        <SmartToyIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    default:
      return (
        <SportsGymnasticsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
  }
};

export default function Home() {
  const services = [
    {
      id: 1,
      iconLabel: "Form",
      iconColor: "#20aab6",
      content: "Customised forms",
      description:
        "Easily adjust forms to accommodate changes in regulations, standards, or operational procedures to capture relevant data points specific to your needs, enabling better analysis",
    },
    {
      id: 2,
      iconLabel: "Reminder",
      iconColor: "#20aab6",
      content: "Schedule reminder",
      description:
        "Send automated reminders to nominated recipients for upcoming inspections, ensuring timely completion and compliance. Customize reminders based on frequency, urgency, and recipient, streamlining your workflow and eliminating the risk of oversight.",
    },
    {
      id: 3,
      iconLabel: "Notification",
      iconColor: "#20aab6",
      content: "Submission notification",
      description:
        "Receive real-time alerts upon inspection form submissions, enabling swift action and decision-making. No login required for viewing detailed reports, ensuring accessibility and efficiency.",
    },
    {
      id: 4,
      iconLabel: "QR",
      iconColor: "#20aab6",
      content: "QR code support",
      description:
        "Easily generate QR codes for each customized form, simplifying access and data entry. Scan, submit, and streamline the inspection process effortlessly.",
    },
    {
      id: 5,
      iconLabel: "Application",
      iconColor: "#20aab6",
      content: "Customized integration",
      description:
        "Offers seamless integration of customized inspection forms into your existing systems. Enhance data flow, streamline processes, and maximize efficiency with  custom integration capabilities",
    },
    {
      id: 6,
      iconLabel: "AI",
      iconColor: "#20aab6",
      content: "AI powered features",
      description: "Coming soon...",
    },
  ];

  return (
    <>
      <Box textAlign={"center"}>
        <Typography
          sx={{ typography: { xs: "h2", sm: "h2", md: "h1" } }}
          style={{
            color: "black",
            lineHeight: "4rem",
            marginTop: "2%",
          }}
        >
          Elevating Inspections, Simplifying Forms
        </Typography>
        <TypeAnimation
          sequence={[
            "Construction and Building Inspections",
            1000,
            "Healthcare Facilities",
            1000,
            "Food Safety and Hygiene Inspections",
            1000,
            "Environmental Compliance Inspections",
            1000,
          ]}
          wrapper="span"
          speed={10}
          deletionSpeed={80}
          cursor={true}
          style={{
            fontSize: "2em",
            display: "inline-block",
            color: palette.text.title,
          }}
          repeat={Infinity}
        />
      </Box>

      <Divider
        style={{
          paddingTop: "5%",
        }}
      />
      <Typography
        sx={{ typography: { xs: "h2s", sm: "h2s", md: "h2" } }}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        Primary Services
      </Typography>

      <Grid
        container
        spacing={5}
        justifyContent="center"
        style={{
          paddingTop: "3%",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} pr={1} key={service.id}>
            <Card
              sx={{
                height: "100%",
                border: 1,
                borderColor: palette.text.title,
                ":hover": {
                  boxShadow: 20,
                },
              }}
            >
              <Box textAlign="center">
                {statusIcon(service.iconLabel, service.iconColor)}
              </Box>
              <CardContent>
                <Box textAlign="center">
                  <Typography variant="h4" gutterBottom>
                    {service.content}
                  </Typography>
                </Box>
                <Typography>{service.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Divider
        style={{
          paddingTop: "5%",
        }}
      />
      <Contact />
    </>
  );
}
