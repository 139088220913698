import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu, { MenuProps } from "@mui/material/Menu";
import { Link } from "react-router-dom";
import palette from "../theme/palette";
import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import "../App.css";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 120;
const navItems = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Cloud",
    href: "/cloud",
  },
  {
    label: "Application Development",
    href: "/app",
  },
  // {
  //   label: "Services",
  //   subMenu: [
  //     {
  //       label: "Cloud Platform",
  //       href: "/cloud",
  //     },
  //     {
  //       label: "Application Development",
  //       href: "/app-development",
  //     },
  //     {
  //       label: "Technology Consulting",
  //       href: "/consulting",
  //     },
  //   ],
  // },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Contact Us",
    href: "/contact",
  },
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(2),
    minWidth: 180,
    backgroundColor: palette.primary.main,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
export default function Header(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundImage:
            "linear-gradient(to right, #8f8f8c 30%,#5370d0 70%,  #8f8f8c 100%)",
        }}
      >
        <Toolbar>
          <IconButton href={"/"} style={{ marginLeft: "3%" }}>
            <img
              height={"39px"}
              src={
                "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
              }
              alt="Logo"
              className="logo"
            />
          </IconButton>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              typography: { xs: "h5w", sm: "h5w", md: "h3w" },
            }}
            style={{
              fontWeight: 1000,
            }}
            color={"white"}
          >
            <span>
              <span style={{ color: "#B32D2A" }}>In</span>spect
              <span style={{ color: "#B32D2A" }}>Form</span>
            </span>
          </Typography>
          <Link
            to={"https://app.inspectform.com"}
            style={{ marginRight: "5%", textDecoration: "none" }}
          >
            <Box
              textAlign={"center"}
              alignContent={"center"}
              sx={{
                backgroundColor: "#276AE8",
                borderRadius: "10px",
                borderColor: "black",
                width: `150px`,
                height: "40px",
                margin: "0 0", // Center the box horizontally
              }}
            >
              <Typography
                color={"white"}
                style={{
                  fontSize: "18px",
                  fontWeight: 1000,
                }}
              >
                Login / Sign Up
              </Typography>
            </Box>
          </Link>
          {/* This Box component is for desktop display*/}
        </Toolbar>
      </AppBar>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}
